<template>
  <v-menu v-if="view !== 'list'" :offset-x="!isButton" :offset-y="isButton" :left="!isButton">
    <template v-slot:activator="{ on }">
      <v-btn v-if="isButton" v-on="on" text>
        Sort List
        <v-icon right>fal fa-chevron-down</v-icon>
      </v-btn>
      <v-list-item v-else v-on="on">
        <v-list-item-avatar>
          <v-icon>fal fa-chevron-left</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{ groupLabel }}</v-list-item-title>
      </v-list-item>
    </template>
    <v-list dense>
      <v-list-item v-for="{ value, text } in sortOptions" :key="value" @click="sortBy = [value]">
        <v-list-item-title>{{ text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
export default {
  props: {
    view: {
      type: String,
      required: true
    },
    isButton: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { root }) {
    const sortBy = computed({
      get: () => root.$store.state.greatscots.resultOptions.sortBy || [ 'name.last' ],
      set: (value) => root.$store.commit('greatscots/setResultOption', { field: 'sortBy', value })
    })
    const sortDesc = computed({
      get: () => root.$store.state.greatscots.resultOptions.sortDesc || [ 0 ],
      set: (value) => root.$store.commit('greatscots/setResultOption', { field: 'sortDesc', value })
    })

    const sortOptions = ref([
      { value: 'name.last', text: 'Last Name' },
      { value: 'name.first', text: 'First Name' },
      { value: 'location.department', text: 'Department' },
      { value: 'locaiton.room', text: 'Room/Office Number' },
      { value: 'location.box', text: 'Mailbox Number' }
    ])

    return {
      sortBy,
      sortDesc,
      sortOptions
    }
  }
}
</script>
